/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
  heroContainer: {
    bg: `white`,
    pt: [6, 6],
    pb: [5, 6],
    px: [4, 0]
  }
}
